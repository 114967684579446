.cardWrapperGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px;
}

.photoFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
}

.captionFlex {
  display: flex;
  flex-direction: column;
}

.caption {
  text-align: center;
}

.addedBy {
  text-align: right;
  font-style: italic;
}