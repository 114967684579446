.memoryCard {
  position: absolute;
  width: 100%;
}

.firstMemory h2 {
  font-size: 50px;
  font-weight: lighter;
}

.paginationFlex {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cardContainerFlex {
  height: fit-content
}

.carousel {
position:relative;
height: 100%;
}

.prevCard {
  left: calc(0% + 2rem);
  opacity: 0;
}

.activeCard {
left: 50%;
transform: translateX(-50%);
}

.nextCard {
  left: 100%;
  transform: translateX(calc(-100% - 2rem));
  opacity: 0;
}

.addRememberanceGrid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center; 
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 2%;
}

.tinyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 751px) {
  .addRememberanceGrid {
    max-width: 700px;
  }
}