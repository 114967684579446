.videoFlex iframe {
  position: relative;
  height: 300px;
  width: 500px;
  margin: 10px 0;
  padding: 0;
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 
    0px 8px 10px 1px rgba(0,0,0,0.14), 
    0px 3px 14px 2px rgba(0,0,0,0.12);
}