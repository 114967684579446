.tinyVideoFlex{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.audioVideoHeader {
  margin: 0 auto 0 auto;
  font-size: 1.25rem;
  width: 1000px;
  max-width: 100%;
}

audio {
  width: 1000px;
  max-width: 100%;
  height: 40px;
}

.audio p {
  margin: 1.25% 0;
}

.center {
  display: block;
  text-align: center;
}

.video p {
  margin: 1.25% 0;
}

.videoTitleList {
  border: 1px solid lightgrey;
  max-height: 10rem;
  min-height: 10rem;
  max-width: 1000px;
  overflow: scroll;
}

.videoTitleList ul {
  margin: 0;
  padding: 0;
}

.videoTitleList li {
  margin: 0;
  padding: 5px;  
  font-size: 20px;
}

.videoTitleList li:hover {
  background-color: #e0d4da;
}

.videoTitleLink {
  list-style-type: none;
  text-decoration: none;
  color: black;
}

.videoTitleLink:visited {
  color: #b04672;
}

.floatingButtonFlex {
  display: flex;
  justify-content: right;
}

.floatingButton {
  position: fixed;
  bottom: 25px;
}

@media (min-width: 1150px) {
  .videoGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 3rem;
    padding: 50px;
    overflow: hidden;
  }
  .videoGrid iframe {
    width: 500px;
    max-width: 100%;
  }
}

@media (max-width: 1149px) {
  .videoGrid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    column-gap: 3rem;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
  }
  .videoGrid iframe {
    width: 500px;
    max-width: 100%;
  }
}