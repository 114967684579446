* {
  margin: 0;
}

body {
  font-family: 'Cormorant', serif;
  font-weight: bolder;
}

h1 {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

h2 {
  text-align: center;
}

li {
  list-style-type: none;
}

p {
  font-size: larger;
  white-space: pre-line;
}

.link {
  color: black;
}

.link:visited {
  color: #b04672;
}

@media (max-width: 749px) {
  body {
    font-weight: 200 !important;
  }
  h1 {
    font-size: 1.5rem;
    margin-top: 50px;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 10px;
  }
  p {
    font-size: 1rem;
  }
  span {
    font-size: 1rem;
  }
  .headerText {
    font-size: 1.5rem !important;
    margin-bottom: 50px;
  }
  .footerText {
    font-size: .75rem !important;
  }
  .welcomeGrid {
    width: 320px !important;
  }
  .welcomeGrid p span {
    font-size: 14px !important;
  }
  .welcomePic{
    min-height: 250px !important;
    min-width: 150px !important;
  }
  .welcomeMainText{
    font-size: 3rem !important;
    margin-top: -115px !important;
    margin-bottom: 30px !important;
  }
  .momName {
    font-size: 1em !important;
  }
  .obitPic {
    display: block;
    width: 250px !important;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .livestreamInfoFlex h3 {
    font-size: 20px !important;
  }
  .embedGrid h3 {
    font-size: 20px !important;
  }
  /* .embedGrid p {
    font-size: 1em !important;
  } */
  .addPhotoGrid {
    grid-template-columns: 1fr !important;
  }
  .photo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .link {
    font-size: 1rem;
  }
  .tinyWrapperRow {
    flex-direction: column !important;
    width: "fit-content";
  }
  .tinyWrapperColumn {
    width: "fit-content"
  }
  .tinyWrapperFlex {
    flex-direction: column !important;
  }
}