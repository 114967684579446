.memServiceWrapperFlex {
  display: flex;
  flex-direction: column;
  width: 900px;
  max-width: 100%;
  white-space: pre-line;
  margin: 0 auto 0 auto;
  text-align: justify;
  font-size: larger;
}

.memServiceWrapperFlex p {
  margin: 0;
}

.memServiceWrapperFlex img {
  width: 500px;
  max-width: 100%;
}

.memServiceWrapperFlex Button {
  width: 200px;
  max-width: 100%;
  margin-top: 8%;
}

.mtOliveFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mtOliveFlex p {
  text-align: center;
}

.textTip {
  font-size: smaller;
}

.livestreamInfoFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: larger;
  width: 1000px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* .livestreamInfoFlex link {
  text-align: center;
} */

.livestreamInfoFlex span {
  padding: 5px;
}

.livestreamInfoFlex li {
  list-style-type: disc;
  font-size: 16px;
  margin: 5px;
}

.embedGrid h3 {
  text-align: center;
}

.embedGrid p {
  text-align: center;
  font-size: small;
  margin-top: 10px;
}

@media (min-width: 1050px) {
  .embedGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 3rem;
    padding: 50px;
  }
  .embedGrid iframe {
    width: 480px;
    max-width: 100%;
    height: 352px;
  }
  .livestreamGrid {
    display: grid;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
  }
  .livestreamGrid iframe {
    height: 550px;
    width: 1000px;
    max-width: 100%;
  }
}

@media (max-width: 1049px) {
  .embedGrid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    column-gap: 3rem;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .embedGrid iframe {
    width: 480px;
    max-width: 100%;
    height: 352px;
  }
  .livestreamGrid {
    display: grid;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
  }
  .livestreamGrid iframe {
    width: 500px;
    max-width: 100%;
  }
}