.modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 90px;
  /* border: 1px solid lightgrey; */
  border-radius: 5px;
  box-shadow: 24;
  color: whitesmoke;
}

.modal svg {
  font-weight: bold;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: auto;
}

.modal svg:hover {
  cursor: pointer;
}