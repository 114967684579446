.mainFlex {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  margin-right: 25px;
  margin-left: 25px;
  margin-top: 50px;
  overflow: hidden;
}

.mainFlex.stacked {
  margin-top: 300px;
}