.mainFooter {
  position: relative;
  text-align: center;
  margin-top: auto;
  padding: 3%;
  z-index: -1;
  background-image:
  linear-gradient(to top, transparent 2%, white),
  url('/public/images/Magnolias.png');
  background-size: cover;
  background-position: center;
}

.footerText {
  font-size: 1rem;
  padding-bottom: 3%;
}