.obitWrapper {
  width: 1000px;
  max-width: 100%;
  height: fit-content;
  margin: 0px auto 0px auto;
  text-align: justify;
  font-size: larger;
}

.obitWrapper h1 {
  font-family: 'Castoro Titling', cursive;  
  margin-bottom: 0px;
  margin-top: 0px;
}

.obitWrapper h2 {
  font-family: 'Castoro Titling', cursive;  
  margin-top: 10px;
  margin-bottom: 40px;
}

.obitWrapper p {
  margin-bottom: 3%;
}

.obitPic {
  width: 400px;
  max-width: 100%;
  border-radius: 5px;
  float: left;
  margin-right: 3%;
  margin-left: 1.5%;
  margin-bottom: 1.5%;
  border: 10px solid #E9E4D4;
  box-shadow: -10px 7px 7px #C1B5A9;
}

.floatingButtonFlex {
  display: flex;
  justify-content: right;
}

.floatingButton {
    position: fixed;
    bottom: 2.5%;
    right: 2.5%;
}

.inLieuWrapperFlex {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  font-style: italic;
  text-align: center;
  margin: 3% auto 2% auto;
}

.tinyWrapperRow {
  display: flex;
  flex-direction: row;
}

.donationButton {
  margin: 20px 40px 0 40px;
}

.donationLink {
  text-decoration: none;
}

.guyanaTvGrid {
  display: grid;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
}

.guyanaTvGrid iframe {
  width: 500px;
  max-width: 100%;
  margin-top: 8%;
  margin-bottom: 8%;
}