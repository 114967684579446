.audioPlayerTrack:active {
  background-color: #b04672;
  color: white;
}

.tinyWrapperFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}