.welcomeFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: 'WindSong', cursive;
  font-size: 2rem;
  color: #404040;
}

.welcomeGrid {
  display: grid;
  grid-template: 20% / repeat(7, 14.25%);
  width: 700px;
  /* max-width: 100%; */
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-items: center;
  align-items: center;
  white-space: nowrap;
  font-size: 1rem;
  text-align: center;
  color: #404040;
}

.sunriseSunsetFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sunriseSunsetFlex img {
  width: 100px;
  max-width: 100%;
}

.sunriseSunsetFlex span {
  font-family: 'Cormorant', serif;
}

.welcomePic {
  border-radius: 50%;
  background-image: url('/public/images/JuneStephenPic.png');
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 8px 8px white inset;
  min-height: 350px;
  min-width: 250px;
  opacity: 0.9;
}


.welcomeMainText {
  font-size: 4rem;
  margin-top: -175px;
}

.momName {
  font-size: 1.2em;
}