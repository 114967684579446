.headerText {
  font-size: 3rem;
  font-weight: bold;
  color: black;
  background-color: rgb(245, 245, 245, .5);
  font-family: 'WindSong', cursive;
  padding: 1% 0;
}

.navbar {
  background-image:
  linear-gradient(to bottom, transparent 50%, white),
  url('/public/images/Magnolias.png');
  background-size: cover;
  background-position: center;
  height: 175px;
  max-height: 100%;
}

.navbarFlex {
  margin-bottom: auto;
}