.photoCard {
  position: absolute;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.paginationFlex {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cardContainerFlex {
  height: fit-content;
}

.carousel {
position:relative;
height: 100%;
padding: 0;
}

.prevCard {
  left: calc(0% + 2rem);
  opacity: 0;
}

.activeCard {
  left: 50%;
  transform: translateX(-50%);
}

.photoCard.nextCard {
  left: 100%;
  transform: translateX(calc(-100% - 2rem));
  opacity: 0;
}

.choosePhotoFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.addPhotoGrid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 2%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 2%;
}

.tinyWrapper {
  display: flex;
  flex-direction: column;
}

@media (min-width: 751px) {
  .addPhotoGrid {
    max-width: fit-content;
  }
}